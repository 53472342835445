export const FETCH_DASHBOARD_DETAILS = {
  RG: {
    code: 'RG',
    id: 2223,
    notificationId: '8',
    moduleId: 'Shipment Planning',
    pageTitle: 'Shipment Planning',
    tableId: 'shipsearchtd',
  },
};

export const INITIALBREADPLAN = [
  { id: 'home', displayValue: 'Home', route: '/home' },
  { id: 'TMS', displayValue: 'TMS', route: undefined },
  { id: 'shpmt_mgmt', displayValue: 'ShipmentManagement', route: undefined },
  {
    id: 'shpmnt_planning',
    displayValue: 'Shipment Planning',
    route: '/home/shipment/shipment-planning',
  },
];

export const metaShipmentPlanningDataGrp = {
  elementId: 'TAG_TB',
  columnList: [
    {
      id: '1001',
      elementId: 'lbl_tms_shipment_xid',
      propertyName: 'Shipment ID',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: true,
      maxLength: 30,
      index: 58,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'xid',
    },
    {
      id: '1002',
      elementId: 'lbl_tms_carrier_id',
      propertyName: 'Carrier ID',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: true,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      dropDownId: '',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'Carrier ID',
    },
    {
      id: '1003',
      elementId: 'lbl_tms_start_location_id',
      propertyName: 'Start Location Id',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 60,
      index: 4,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'H',
      dataType: 'String',
      cellDataName: 'sourceLocationName',
    },
    {
      id: '1004',
      elementId: 'lbl_tms_end_location_id',
      propertyName: 'End Location Id',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: true,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      dropDownId: '',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'destLocationName',
    },
    {
      id: '1005',
      elementId: 'lbl_tms_no_of_stops',
      propertyName: 'No of Stops',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      dropDownId: '',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'totalStopCount',
    },
    {
      id: '1006',
      elementId: 'lbl_tms_start_time',
      propertyName: 'Start Time',
      type: 'DATETIME',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'planStartShippingDate',
    },
    // {
    //     'id': '1007',
    //     'elementId': 'lbl_tms_end_time',
    //     'propertyName': 'End Time',
    //     'type': 'DATETIME',
    //     'defaultVal': '',
    //     'defaultOp': '=',
    //     'isMandatory': false,
    //     'maxLength': 20,
    //     'index': 45,
    //     'priority': 'LOW',
    //     'toolTip': null,
    //     'values': [],
    //     'columnGroup': 'Primary',
    //     'displayType': 'E',
    //     'dataType': 'String',
    //     'cellDataName': 'planDeliveryDate'
    // },
    {
      id: '1008',
      elementId: 'lbl_tms_volume',
      propertyName: 'Volume(CBM)',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'totalGrossVolume',
    },
    {
      id: '1009',
      elementId: 'lbl_tms_weight',
      propertyName: 'Weight(KG)',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'totalGrossWeight',
    },
    {
      id: '1010',
      elementId: 'lbl_tms_planned_truck_type',
      propertyName: 'Equip Type',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'equipmentName',
    },
    {
      id: '1010',
      elementId: 'lbl_tms_principal_id',
      propertyName: 'Principal',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'equipmentName',
    },
    {
      id: '1010',
      elementId: 'lbl_tms_start_location_id',
      propertyName: 'Source Location ID',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'equipmentName',
    },
    {
      id: '1012',
      elementId: 'lbl_tms_status',
      propertyName: 'Status',
      type: 'DROPDOWN',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [
        { key: '', value: '-Select-' },
        { key: 'Open', value: 'Open' },
        { key: 'FINALIZED', value: 'Finalized' },
        { key: 'PENDING', value: 'Pending Booking' },
        { key: 'DECLINED', value: 'Declined' },
        { key: 'BOOKED', value: 'Booked' },
        { key: 'IN-TRANSIT', value: 'In Transit' },
        { key: 'DELIVERED', value: 'Delivered' },
        { key: 'DELIVERY-FAILURE', value: 'Delivery Failure' },
        { key: 'CANCELLED', value: 'Cancelled' },
        { key: 'TRANSFER-ON-HOLD', value: 'Transfer On Hold' },
        { key: 'SUSPENDED', value: 'Suspended' },
        // { 'key': 'TRANSFERRED', 'value': 'Transferred' },
        // { 'key': 'DELIVERED-LATE', 'value': 'Delivered Late' },
      ],
      columnGroup: 'Primary',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'Status',
    },
    {
      id: '1011',
      elementId: 'lbl_tms_customer_ref_no',
      propertyName: 'Client Ref No',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: true,
      maxLength: 30,
      index: 58,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'clientReferenceNo',
    },
    {
      id: '1011',
      elementId: 'lbl_tms_forecast_order_code',
      propertyName: 'Order Release Xid',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: true,
      maxLength: 30,
      index: 58,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'Order ID',
    },
    {
      id: '1011',
      elementId: 'lbl_tms_source_order_id',
      propertyName: 'Order Source ID',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: true,
      maxLength: 30,
      index: 58,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'Order Source ID',
    },
    {
      id: '1013',
      elementId: 'lbl_tms_driver_name',
      propertyName: 'Driver Name',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: true,
      maxLength: 30,
      index: 58,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'Driver Name',
    },
    {
      id: '1014',
      elementId: 'lbl_tms_vehicle_no',
      propertyName: 'Vehicle No',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: true,
      maxLength: 30,
      index: 58,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'Vehicle No',
    },
    {
      id: '1015',
      elementId: 'lbl_tms_actual_truck_type',
      propertyName: 'Vehicle Type',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: true,
      maxLength: 30,
      index: 58,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'Vehicle Type',
    },
    {
      id: '1016',
      elementId: 'driver',
      propertyName: 'Driver Code',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: true,
      maxLength: 30,
      index: 58,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'Driver Code',
    },
    {
      id: '1010',
      elementId: 'lbl_tms_county',
      propertyName: 'County',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'County',
    },
    {
      id: '1010',
      elementId: 'lbl_tms_postcode',
      propertyName: 'Post Code',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'postcode',
    },
  ],
};

export const metaDataGrpDetail = {
  elementId: 'TAG_TB',
  columnList: [
    {
      id: '1001',
      elementId: 'Tag',
      propertyName: 'TAG',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'V',
      dataType: 'String',
      cellDataName: 'tag',
    },
    {
      id: '1002',
      elementId: 'Tag Label',
      propertyName: 'TAG_LABEL',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'taglabel',
    },
    {
      id: '1003',
      elementId: 'Sample Value',
      propertyName: 'SAMPLE_VALUE',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 20,
      index: 45,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'samplevalue',
    },
    {
      id: '1004',
      elementId: 'Description',
      propertyName: 'DESCRIPTION',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 100,
      index: 6,
      priority: 'LOW',
      toolTip: null,
      columnGroup: 'Primary',
      displayType: 'E',
      dataType: 'String',
      cellDataName: 'descriptiontg',
    },
    {
      id: '1000',
      elementId: 'tag Id',
      propertyName: 'TAG_ID',
      type: 'TEXTFIELD',
      defaultVal: '',
      defaultOp: '=',
      isMandatory: false,
      maxLength: 30,
      index: 58,
      priority: 'LOW',
      toolTip: null,
      values: [],
      columnGroup: 'Primary',
      displayType: 'H',
      dataType: 'String',
      cellDataName: 'tagId',
    },
  ],
};
